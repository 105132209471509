<template>
    <div id="self-publishing-flow-quote">
        <transition mode="out-in" name="fade" @after-leave="focusQuantityInput">
            <APILoading v-if="loading.components" key="loading" class="min-vh-50" :font-size="24"
                        :text="$t('SelfPublishingFlowQuote.loadingQuoteCalculator')"/>
            <div v-else key="quote-calculator">
                <form @submit.prevent="fillBookComponents">
                    <div class="row">
                        <div class="col-12 row align-items-center text-center">
                            <i class="fa fa-calculator fa-3x text-primary col-1"/>
                            <div class="col-11">
                                <h5 class="mb-0" v-html="this.$t('SelfPublishingFlowQuote.enterBookComponents')"/>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-3 mb-3"/>
                    <div class="row mb-4">
                        <div class="col-10 row">
                            <div class="col-6">
                                <div class="card shadow-lg">
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fas fa-arrows-alt-v mr-1"/>
                                        <i class="fa fa-book mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.bookFormat')}`"/>
                                    </div>
                                    <div class="card-body bg-white d-flex justify-content-center flex-column">
                                        <div class="row align-items-center">
                                            <div class="col-12">
                                                <label for="book-format" v-html="$t('SelfPublishingFlowQuote.closedBookFormat')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-12">
                                                <v-select :value="selectedFormat" @input="setSelectedFormat" input-id="book-format"
                                                          :options="formats" label="name" :placeholder="$t('SelfPublishingFlowQuote.choose')"
                                                          :clearable="false" :disabled="loading.summary">
                                                    <template slot="option" slot-scope="option">
                                                        <span v-html="displayFormat(option)"/>
                                                    </template>
                                                    <template #selected-option="option">
                                                        <span v-html="displayFormat(option)"/>
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                        <div v-if="!selectedFormat.width" class="row mt-2">
                                            <div class="col-12 font-italic text-muted d-flex align-items-center">
                                                <i class="fa fa-exclamation-triangle mr-2"/>
                                                <span v-html="$t('SelfPublishingFlowQuote.assistedProductCreatedMandatoryForCustomDimensions')"/>
                                            </div>
                                            <div class="col-12">
                                                <label v-html="$t('SelfPublishingFlowQuote.customBookDimensions')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="row col-12 align-items-center">
                                                <div class="col-4">
                                                    <label for="custom-width" v-html="$t('SelfPublishingFlowQuote.bookWidth')"/>
                                                    <RedAsterisk/>
                                                </div>
                                                <div class="col-8">
                                                    <input id="custom-width" v-model="localSelfPublishedBook.dimensions.width"
                                                           class="form-control" type="number" :min="customDimensionsLimits.width.min"
                                                           :max="customDimensionsLimits.width.max" required/>
                                                </div>
                                            </div>
                                            <div class="row col-12 align-items-center my-1">
                                                <div class="col-4">
                                                    <label for="custom-height" v-html="$t('SelfPublishingFlowQuote.bookHeight')"/>
                                                    <RedAsterisk/>
                                                </div>
                                                <div class="col-8">
                                                    <input id="custom-height" v-model="localSelfPublishedBook.dimensions.height"
                                                           class="form-control" type="number" :min="customDimensionsLimits.height.min"
                                                           :max="customDimensionsLimits.height.max" required/>
                                                </div>
                                            </div>
                                            <transition mode="out-in" name="fade">
                                                <div v-if="localSelfPublishedBook.dimensions.width && localSelfPublishedBook.dimensions.height && !areCustomDimensionsValid"
                                                     class="row col-12">
                                                    <div class="col-12 text-center">
                                                        <span class="font-italic red-text" v-html="customDimensionsNotValidText"/>
                                                    </div>
                                                </div>
                                            </transition>
                                            <div class="col-12">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-2">
                                            <div class="col-12">
                                                <label for="book-format-type" v-html="$t('SelfPublishingFlowQuote.openedBookFormat')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-10 form-group mb-0">
                                                <div class="custom-control text-center custom-radio custom-control-inline">
                                                    <input class="custom-control-input" type="radio" v-model="localSelfPublishedBook.dimensions.orientation"
                                                           id="book-format-type-french" value="portrait" :disabled="loading.summary"/>
                                                    <label class="custom-control-label" for="book-format-type-french"
                                                           v-html="$t('SelfPublishingFlowQuote.formatType.french')"/>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input class="custom-control-input" type="radio" v-model="localSelfPublishedBook.dimensions.orientation"
                                                           id="book-format-type-italian" value="landscape" :disabled="loading.summary"/>
                                                    <label class="custom-control-label" for="book-format-type-italian"
                                                           v-html="$t('SelfPublishingFlowQuote.formatType.italian')"/>
                                                </div>
                                            </div>
                                            <div class="col-2 text-center">
                                                <i v-if="localSelfPublishedBook.dimensions.orientation" id="book-orientation-icon"
                                                   class="fa fa-file fadeIn text-primary" v-tooltip="bookOrientationIconTooltip"
                                                   :class="{ 'fa-rotate-90': localSelfPublishedBook.dimensions.orientation === 'landscape' }"/>
                                            </div>
                                            <div class="col-12">
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.openedFormat.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <ul class="text-left">
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.openedFormat.french.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.openedFormat.french.description')}`"/>
                                                            </li>
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.openedFormat.italian.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.openedFormat.italian.description')}`"/>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fa fa-link mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.bookBinding')}`"/>
                                    </div>
                                    <div class="card-body bg-white d-flex justify-content-center flex-column">
                                        <div class="row align-items-center">
                                            <div class="col-12">
                                                <label for="book-binding" v-html="$t('SelfPublishingFlowQuote.binding')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-12">
                                                <v-select :value="selectedBinding" @input="setSelectedBinding" input-id="book-binding"
                                                          :options="bindings" label="name" :placeholder="$t('SelfPublishingFlowQuote.choose')"
                                                          :clearable="false" :disabled="loading.summary"/>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.howToChooseBinding.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="$t('SelfPublishingFlowQuote.howToChooseBinding.DCC')"/>
                                                        <p class="text-left">
                                                            <i class="fa fa-info-circle text-primary mr-1"/>
                                                            <span v-html="$t('SelfPublishingFlowQuote.howToChooseBinding.steamGlue')"/>
                                                        </p>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fa fa-file mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.printApproval')}`"/>
                                    </div>
                                    <div class="card-body bg-white d-flex justify-content-center flex-column">
                                        <div class="col-12">
                                            <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                                <input class="custom-control-input" type="checkbox" id="physical-printing-approval"
                                                       v-model="options['physical-printing-approval']"/>
                                                <label class="custom-control-label c-pointer" for="physical-printing-approval"
                                                       v-html="$t('SelfPublishingFlowQuote.paperPrintApproval')"/>
                                            </div>
                                            <p class="text-muted font-italic text-sm" v-html="`(${$t('SelfPublishingFlowQuote.freeForNewClient')})`"/>
                                            <p class="text-less-bold" v-html="`+ ${properPrice(15, 'EUR', currencies)} ${$t('SelfPublishingFlowQuote.withTaxes')}`"/>
                                        </div>
                                        <div class="col-12">
                                            <v-popover>
                                                <a class="text-sm font-italic" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                    <span v-html="$t('SelfPublishingFlowQuote.whatIsPrintingApproval.question')"/>
                                                </a>
                                                <template slot="popover">
                                                    <div class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whatIsPrintingApproval.answer')}`"/>
                                                </template>
                                            </v-popover>
                                        </div>
                                        <transition name="fade">
                                            <div v-if="options['physical-printing-approval']" class="col-12">
                                                <hr class="my-2"/>
                                                <span class="fa fa-exclamation-triangle mr-2 text-primary"/>
                                                <span v-html="$t('SelfPublishingFlowQuote.physicalPrintingApprovalWillDelayOrder')"/>
                                            </div>
                                        </transition>
                                    </div>
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fas fa-book-reader mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.culturalProduct')}`"/>
                                    </div>
                                    <div class="card-body bg-white">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="custom-control custom-checkbox custom-control-inline c-pointer d-flex align-items-center">
                                                    <input id="reduced-tax-input" class="custom-control-input" type="checkbox"
                                                           v-model="localSelfPublishedBook.reducedTax"/>
                                                    <label class="custom-control-label c-pointer" for="reduced-tax-input"
                                                           v-html="$t('SelfPublishingFlowQuote.myProductIsCultural')"/>
                                                </div>
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.whatIsCulturalProduct.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whatIsCulturalProduct.description')}`"/>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card shadow-lg">
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fa fa-book mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.bookCover')}`"/>
                                    </div>
                                    <div class="card-body bg-white">
                                        <div class="row align-items-center mt-2">
                                            <div class="col-12">
                                                <label for="book-cover-paper" v-html="$t('SelfPublishingFlowQuote.paperType')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-12">
                                                <v-select :value="selectedCoverPaper" @input="setSelectedCoverPaper" input-id="book-cover-paper"
                                                          :options="cover.papers" label="name" :placeholder="$t('SelfPublishingFlowQuote.choose')"
                                                          :clearable="false" :disabled="loading.summary"/>
                                            </div>
                                            <div class="col-12">
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.whichPaperForCover.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCover.forOneSidePrinting')}`"/>
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCover.forBothSidesPrinting')}`"/>
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCover.aboutTexturedPapers')}`"/>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-2">
                                            <div class="col-12">
                                                <label for="book-cover-lamination" v-html="$t('SelfPublishingFlowQuote.lamination')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-12">
                                                <v-select :value="selectedCoverLamination" @input="setSelectedCoverLamination" input-id="book-cover-lamination"
                                                          :options="cover.laminations" label="name" :placeholder="$t('SelfPublishingFlowQuote.choose')"
                                                          :clearable="false" :disabled="isLaminationInputDisabled"/>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.howToChooseLamination.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.description')}`"/>
                                                        <ul class="text-left">
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.dullLamination.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.dullLamination.description')}`"/>
                                                            </li>
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.glossyLamination.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.glossyLamination.description')}`"/>
                                                            </li>
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.withoutLamination.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.howToChooseLamination.withoutLamination.description')}`"/>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-12">
                                                <label v-html="$t('SelfPublishingFlowQuote.coverPrinting')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-10 form-group mb-0">
                                                <div class="custom-control text-center custom-radio custom-control-inline">
                                                    <input class="custom-control-input" type="radio" v-model="localSelfPublishedBook.components.cover.printing.bothSides"
                                                           id="cover-printing-one-side" :value="false" :disabled="isCoverPrintingInputDisabled"/>
                                                    <label class="custom-control-label" for="cover-printing-one-side"
                                                           v-html="$t('SelfPublishingFlowQuote.oneSide')"/>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                    <input class="custom-control-input" type="radio" v-model="localSelfPublishedBook.components.cover.printing.bothSides"
                                                           id="cover-printing-both-sides" :value="true" :disabled="isCoverPrintingInputDisabled"/>
                                                    <label class="custom-control-label" for="cover-printing-both-sides"
                                                           v-html="$t('SelfPublishingFlowQuote.bothSides')"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fa fa-book-open mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.bookCorpus')}`"/>
                                    </div>
                                    <div class="card-body bg-white">
                                        <div class="row align-items-center">
                                            <div class="col-5">
                                                <label for="book-corpus-paper" v-html="$t('SelfPublishingFlowQuote.paperType')"/>
                                                <RedAsterisk/>
                                            </div>
                                            <div class="col-12">
                                                <v-select :value="selectedCorpusPaper" @input="setSelectedCorpusPaper" input-id="book-corpus-paper"
                                                          :options="corpus.papers" label="name" :placeholder="$t('SelfPublishingFlowQuote.choose')"
                                                          :clearable="false" :disabled="loading.summary"/>
                                            </div>
                                            <div class="col-12 mt-1">
                                                <v-popover>
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.whichPaperForCorpus.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.weight')}`"/>
                                                        <hr/>
                                                        <ul class="text-left">
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.bulky.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.bulky.description')}`"/>
                                                            </li>
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.unCoated.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.unCoated.description')}`"/>
                                                            </li>
                                                            <li>
                                                                <div class="text-less-bold" v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.coated.name')}:`"/>
                                                                <div v-html="`${$t('SelfPublishingFlowQuote.whichPaperForCorpus.coated.description')}`"/>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                </v-popover>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-2">
                                            <div class="col-12 mb-3">
                                                <label for="total-pages" v-html="$t('SelfPublishingFlowQuote.paging')"/>
                                                <RedAsterisk/>
                                                <hr/>
                                            </div>
                                            <div class="col-4">
                                                <input id="black-pages" v-model="localSelfPublishedBook.paging.black"
                                                       class="form-control" type="number" :min="0" :max="maxBlackPages"
                                                       required/>
                                            </div>
                                            <div class="col-8">
                                                <label for="black-pages" v-html="$t('SelfPublishingFlowQuote.blackPages')"/>
                                                <RedAsterisk/>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-1">
                                            <div class="col-4">
                                                <input id="color-pages" class="form-control"
                                                       v-model="localSelfPublishedBook.paging.color" type="number" :min="0"
                                                       :max="maxColorPages" required/>
                                            </div>
                                            <div class="col-8">
                                                <label for="color-pages" v-html="$t('SelfPublishingFlowQuote.colorPages')"/>
                                                <RedAsterisk/>
                                            </div>
                                        </div>
                                        <div class="row align-items-center">
                                            <div class="col-12 text-center text-less-bold">
                                                <hr class="mt-2 mb-2"/>
                                                <transition mode="out-in" name="fade">
                                                <span v-if="totalPages" key="total-pages"
                                                      v-html="$t('SelfPublishingFlowQuote.totalPages', { totalPages })"/>
                                                    <span v-else key="missing-paging-values" v-html="$t('SelfPublishingFlowQuote.pleaseFillPaging')"/>
                                                </transition>
                                            </div>
                                        </div>
                                        <transition mode="out-in" name="fade">
                                            <div v-if="totalPages < selfPublishedProductsMinTotalPages" class="row">
                                                <div class="col-12 text-center">
                                                    <span class="font-italic red-text"
                                                          v-html="$t('SelfPublishingFlowQuote.minPagesNotReached', { min: selfPublishedProductsMinTotalPages })"/>
                                                </div>
                                            </div>
                                            <div v-else-if="totalPages > 650" class="row">
                                                <div class="col-12 text-center">
                                                    <span class="font-italic red-text" v-html="$t('SelfPublishingFlowQuote.maxPagesReached')"/>
                                                </div>
                                            </div>
                                            <div v-else-if="totalPages % 4 !== 0" class="row">
                                                <div class="col-12 text-center">
                                                    <span class="font-italic red-text" v-html="$t('SelfPublishingFlowQuote.totalPagesMustMultipleOfFour')"/>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="card shadow-lg">
                                    <div class="card-header bg-primary text-white text-lg">
                                        <i class="fas fa-file-pdf mr-2"/>
                                        <span v-html="`${$t('SelfPublishingFlowQuote.files')}`"/>
                                    </div>
                                    <div class="card-body bg-white">
                                        <div class="row align-items-center justify-content-around">
                                            <div v-if="isProductCreationWorkshopEnabled"
                                                 class="col-4 d-flex flex-column align-items-center justify-content-center">
                                                <span v-html="$t('SelfPublishingFlowQuote.recommended')" class="badge badge-primary text-uppercase mb-2"/>
                                                <div class="custom-control custom-radio d-flex align-items-center">
                                                    <input class="custom-control-input" type="radio" id="product-creation-workshop" value="product-creation-workshop"
                                                           v-model="options['product-creation']" :disabled="areOptionsDisabled"/>
                                                    <label class="custom-control-label c-pointer" for="product-creation-workshop"
                                                           v-html="$t('SelfPublishingFlowQuote.productCreationWorkshop')"/>
                                                </div>
                                                <p class="text-less-bold mt-2" v-html="`+ ${properPrice(15, 'EUR', currencies)} ${$t('SelfPublishingFlowQuote.withTaxes')}`"/>
                                                <v-popover class="text-center">
                                                    <a class="text-sm font-italic" href="#" @click.prevent>
                                                        <i class="fa fa-question-circle mr-2"/>
                                                        <span v-html="$t('SelfPublishingFlowQuote.doINeedProductCreationWorkshop.question')"/>
                                                    </a>
                                                    <template slot="popover">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.doINeedProductCreationWorkshop.description')}`"/>
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.doINeedProductCreationWorkshop.workshopProvides')}`"/>
                                                        <ol class="text-left">
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedProductCreationWorkshop.coverWork')"/>
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedProductCreationWorkshop.corpusWork')"/>
                                                        </ol>
                                                    </template>
                                                </v-popover>
                                            </div>
                                            <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                                                <span v-html="$t('SelfPublishingFlowQuote.withoutWorries')" class="badge badge-info text-uppercase mb-2"/>
                                                <div class="custom-control custom-radio c-pointer d-flex align-items-center">
                                                    <input class="custom-control-input" type="radio" id="assisted-product-creation"
                                                           value="assisted-product-creation"
                                                           v-model="options['product-creation']" :disabled="areOptionsDisabled"/>
                                                    <label class="custom-control-label c-pointer" for="assisted-product-creation"
                                                           v-html="$t('SelfPublishingFlowQuote.assistedProductCreationPlan')"/>
                                                </div>
                                                <p class="text-less-bold mt-2" v-html="`+ ${properPrice(45, 'EUR', currencies)} ${$t('SelfPublishingFlowQuote.withTaxes')}`"/>
                                            </div>
                                            <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                                                <span v-html="$t('SelfPublishingFlowQuote.expert')" class="badge badge-danger text-uppercase mb-2"/>
                                                <span class="custom-control custom-radio d-flex align-items-center">
                                                    <input class="custom-control-input" type="radio" id="manual-import" :value="null"
                                                           v-model="options['product-creation']" :disabled="areOptionsDisabled"/>
                                                    <label class="custom-control-label c-pointer" for="manual-import"
                                                           v-html="$t('SelfPublishingFlowQuote.manualImport')"/>
                                                </span>
                                                <p class="text-less-bold mt-2" v-html="`${properPrice(0, 'EUR', currencies)}`"/>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div class="row p-3">
                                            <div class="col-12">
                                                <transition name="fade" mode="out-in">
                                                    <div v-if="!options['product-creation']" key="manual-import-description">
                                                        <p v-html="`${$t('SelfPublishingFlowQuote.isManualImportForMe.description')}`"/>
                                                    </div>
                                                    <div v-else key="assisted-product-creation-description">
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.description')}`"/>
                                                        <p class="text-left" v-html="`${$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.assistanceProvides')}`"/>
                                                        <ol class="text-left">
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.documentScaling')"/>
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.putAtRightSize')"/>
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.bleedsCreation')"/>
                                                            <li v-html="$t('SelfPublishingFlowQuote.doINeedAssistedProductCreationPlan.contact')"/>
                                                        </ol>
                                                    </div>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 d-flex flex-column justify-content-between">
                            <div class="card shadow-lg">
                                <div class="card-header bg-primary text-white text-lg">
                                    <i class="fas fa-sort-numeric-up-alt mr-2"/>
                                    <span v-html="`${$t('SelfPublishingFlowQuote.quantity')}`"/>
                                </div>
                                <div class="card-body bg-white">
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="form-control text-center" type="number" v-model="localSelfPublishedBook.quantity"
                                                   min="1" max="500" ref="quantityInput"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <transition mode="out-in" name="fade">
                                <div v-if="!isFormValid" class="text-center text-muted mt-4" key="invalid-form-disclaimer">
                                    <i class="fa fa-calculator fa-3x"/>
                                    <div class="font-italic mt-1" v-html="$t('SelfPublishingFlowQuote.pleaseFillAllRequiredFields')"/>
                                </div>
                                <APILoading v-else-if="loading.summary" class="h-50" :font-size="24" text="" key="loading"/>
                                <div v-else-if="!isBookSellable" class="text-center text-muted mt-4" key="unsellable-book-disclaimer">
                                    <i class="fa fa-calculator fa-3x"/>
                                    <i class="fa fa-times text-danger fa-3x ml-2"/>
                                    <div class="font-italic mt-1" v-html="$t('SelfPublishingFlowQuote.impossibleQuoteWithProvidedBookComponents')"/>
                                </div>
                                <div v-else class="mt-2" key="summary">
                                    <div class="card shadow-lg">
                                        <div class="card-header bg-primary text-white text-lg text-capitalize text-less-bold text-center">
                                            <span v-html="`${$t('SelfPublishingFlowQuote.summary').toUpperCase()}`"/>
                                        </div>
                                        <div class="card-body bg-white">
                                            <div class="row align-items-center text-center mb-1">
                                                <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.unitPrice')} ${$t('SelfPublishingFlowQuote.withoutTaxes')}`"/>
                                                <span class="col-12 text-less-bold" v-html="this.properPrice(summary.book.price.unit, 'EUR', currencies)"/>
                                            </div>
                                            <hr/>
                                            <div class="row align-items-center text-center my-1">
                                                <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.totalPrice')} ${$t('SelfPublishingFlowQuote.withoutTaxes')}`"/>
                                                <span class="col-12 text-less-bold" v-html="this.properPrice(summary.book.price.total, 'EUR', currencies)"/>
                                            </div>
                                            <hr/>
                                            <div class="row align-items-center text-center my-1">
                                                <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.delivery')} ${$t('SelfPublishingFlowQuote.withoutTaxes')}`"/>
                                                <span class="col-12 text-less-bold" v-html="this.properPrice(summary.delivery.price, 'EUR', currencies)"/>
                                            </div>
                                            <hr/>
                                            <div class="row align-items-center text-center my-4">
                                                <span class="col-12 text-less-bold text-primary" v-html="`${$t('SelfPublishingFlowQuote.totalPrice')} ${$t('SelfPublishingFlowQuote.withTaxes')}`"/>
                                                <span id="summary-total-price" class="col-12" v-html="this.properPrice(summary.totalWT, 'EUR', currencies)"/>
                                            </div>
                                            <div v-if="takenOptions.length">
                                                <hr/>
                                                <div class="row my-2">
                                                    <div class="col-12 text-less-bold text-center text-sm text-primary"
                                                         v-html="$t('SelfPublishingFlowQuote.optionsIncludedInUnitPrice')"/>
                                                </div>
                                                <div v-if="options['physical-printing-approval']">
                                                    <hr/>
                                                    <div class="row align-items-center text-center my-2">
                                                        <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.paperPrintApproval')} ${$t('SelfPublishingFlowQuote.withTaxes')}:`"/>
                                                        <span class="col-12 text-less-bold" v-html="this.properPrice(physicalPrintingApprovalOptionInSummary.charge.priceWT, 'EUR', currencies)"/>
                                                    </div>
                                                </div>
                                                <div v-if="options['product-creation'] === 'assisted-product-creation'">
                                                    <hr/>
                                                    <div class="row align-items-center text-center my-2">
                                                        <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.assistedProductCreationPlan')} ${$t('SelfPublishingFlowQuote.withTaxes')}:`"/>
                                                        <span class="col-12 text-less-bold" v-html="this.properPrice(assistedProductCreationOptionInSummary.charge.priceWT, 'EUR', currencies)"/>
                                                    </div>
                                                </div>
                                                <div v-else-if="options['product-creation'] === 'product-creation-workshop'">
                                                    <hr/>
                                                    <div class="row align-items-center text-center my-2">
                                                        <span class="col-12 summary-label" v-html="`${$t('SelfPublishingFlowQuote.productCreationWorkshop')} ${$t('SelfPublishingFlowQuote.withTaxes')}:`"/>
                                                        <span class="col-12 text-less-bold" v-html="this.properPrice(productCreationWorkshopOptionInSummary.charge.priceWT, 'EUR', currencies)"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row my-2">
                                                <div class="col-12 text-less-bold text-center text-sm text-primary"
                                                     v-html="$t('SelfPublishingFlowQuote.bookFeatures')"/>
                                            </div>
                                            <hr/>
                                            <div class="row align-items-center text-center my-1">
                                                <span class="summary-label col-12" v-html="`${$t('SelfPublishingFlowQuote.bookWeight')}`"/>
                                                <span class="text-less-bold col-12" v-html="`${summary.book.weight} g`"/>
                                            </div>
                                            <hr/>
                                            <div class="row align-items-center text-center my-1">
                                                <span class="summary-label col-12" v-html="`${$t('SelfPublishingFlowQuote.bookThickness')}`"/>
                                                <span class="text-less-bold col-12" v-html="`${summary.book.thickness} mm`"/>
                                            </div>
                                        </div>
                                    </div>
                                    <form @submit.prevent="downloadQuote">
                                        <div class="row">
                                            <div class="col-12">
                                                <SubmitBtn classes="btn btn-primary btn-sm btn-block"
                                                           :disabled="!isUserLogged" :loading="loading.downloadQuote"
                                                           :disabled-tooltip-text="$t('SelfPublishingFlowQuote.youMustBeLoggingInToDownloadQuote')"
                                                           :text="$t('SelfPublishingFlowQuote.downloadQuote')"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </transition>
                            <div>
                                <button type="submit" :disabled="!isStepValid || isLoading" class="btn btn-primary btn-block mb-0"
                                        v-html="$t('SelfPublishingFlowQuote.continue')"/>
                            </div>
                        </div>
                    </div>
                </form>
                <hr class="mt-3 mb-3"/>
                <SelfPublishingFlowQuoteFooterImprimerVosLivres v-if="APIClient === 'imprimer-vos-livres'"/>
                <SelfPublishingFlowQuoteFooterLaBoutiqueSoBook v-else-if="APIClient === 'la-boutique-sobook'"/>
                <SelfPublishingFlowQuoteFooterGutenberg v-else-if="APIClient === 'gutenberg'"/>
                <SelfPublishingFlowQuoteFooterDefault v-else/>
            </div>
        </transition>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import FileSaver from "file-saver";
import { mapActions, mapGetters } from "vuex";
import uniqueId from "uniqid";
import { properPrice } from "@/helpers/price";
import Product from "../../../../../classes/Product";
import APILoading from "../../../../shared/Misc/APILoading";
import RedAsterisk from "../../../../shared/Forms/RedAsterisk";
import SelfPublishingFlowQuoteFooterImprimerVosLivres from "./SelfPublishingFlowQuoteFooter/SelfPublishingFlowQuoteFooterImprimerVosLivres";
import SelfPublishingFlowQuoteFooterDefault from "./SelfPublishingFlowQuoteFooter/SelfPublishingFlowQuoteFooterDefault";
import SelfPublishingFlowQuoteFooterLaBoutiqueSoBook
    from "./SelfPublishingFlowQuoteFooter/SelfPublishingFlowQuoteFooterLaBoutiqueSoBook";
import SubmitBtn from "../../../../shared/Misc/SubmitBtn";
import SelfPublishingFlowQuoteFooterGutenberg
    from "@/components/Hermes/SelfPublishing/SelfPublishingCreateBook/SelfPublishingFlow/SelfPublishingFlowQuoteFooter/SelfPublishingFlowQuoteFooterGutenberg";

export default {
    name: "SelfPublishingFlowQuote",
    components: {
        SelfPublishingFlowQuoteFooterGutenberg,
        SubmitBtn,
        SelfPublishingFlowQuoteFooterLaBoutiqueSoBook,
        SelfPublishingFlowQuoteFooterDefault,
        SelfPublishingFlowQuoteFooterImprimerVosLivres,
        RedAsterisk,
        APILoading,
    },
    props: {
        selfPublishedBook: {
            type: Product,
            required: true,
        },
    },
    data() {
        return {
            loading: {
                components: true,
                summary: false,
                downloadQuote: false,
            },
            getQuoteRequestIds: [],
            formats: [
                { name: "A4", height: 297, width: 210 },
                { name: "A5", height: 210, width: 148 },
                { name: "Poche", height: 180, width: 110 },
                { name: "Prestige", height: 235, width: 155 },
                { name: "Personnalisé" },
            ],
            bindings: null,
            cover: {
                printings: null,
                papers: null,
                laminations: null,
            },
            corpus: {
                papers: null,
            },
            localSelfPublishedBook: new Product(this.selfPublishedBook),
            summary: {
                delivery: {
                    price: 0,
                    priceWT: 0,
                },
                book: {
                    weight: 0,
                    thickness: 0,
                    price: {
                        unit: 0,
                        total: 0,
                    },
                    priceWT: {
                        unit: 0,
                        total: 0,
                    },
                    options: [],
                },
                totalWT: 0,
            },
            options: {
                "physical-printing-approval": false,
                "product-creation": "assisted-product-creation",
            },
        };
    },
    computed: {
        ...mapGetters("config", {
            APIClient: "client",
            isProductCreationWorkshopEnabled: "isProductCreationWorkshopEnabled",
            selfPublishedProductsMinTotalPages: "selfPublishedProductsMinTotalPages",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
        isStepValid() {
            return !!(this.isFormValid && this.isBookSellable);
        },
        isFormValid() {
            const { components, dimensions, quantity } = this.localSelfPublishedBook;
            return !!(components.binding.id && components.corpus.paper.id && components.cover.paper.id &&
                components.cover.printing.bothSides !== undefined && components.cover.lamination.id && dimensions.width &&
                dimensions.height && dimensions.width && dimensions.orientation && this.areCustomDimensionsValid &&
                this.totalPages >= this.selfPublishedProductsMinTotalPages && this.totalPages <= 650 && this.totalPages % 2 === 0 && quantity);
        },
        isBookSellable() {
            return !!this.summary.totalWT && !!this.summary.book.price.total;
        },
        selectedFormat() {
            if (this.formats) {
                const { dimensions: bookDimensions } = this.localSelfPublishedBook;
                const bookWidth = bookDimensions.orientation === "portrait" ? bookDimensions.width : bookDimensions.height;
                const bookHeight = bookDimensions.orientation === "portrait" ? bookDimensions.height : bookDimensions.width;
                const existingFormat = this.formats.find(({ width, height }) => width === bookWidth && height === bookHeight);
                return existingFormat ? existingFormat : this.formats[this.formats.length - 1];
            }
            return null;
        },
        selectedBinding() {
            if (this.bindings) {
                const existingBinding = this.bindings.find(binding => binding.id === this.localSelfPublishedBook.components.binding.id);
                return existingBinding ? existingBinding : null;
            }
            return null;
        },
        selectedCoverPaper() {
            if (this.cover.papers) {
                const coverPaper = this.cover.papers.find(paper => paper.id === this.localSelfPublishedBook.components.cover.paper.id);
                return coverPaper ? coverPaper : null;
            }
            return null;
        },
        selectedCoverLamination() {
            if (this.cover.laminations) {
                const coverLamination = this.cover.laminations.find(lamination => lamination.id === this.localSelfPublishedBook.components.cover.lamination.id);
                return coverLamination ? JSON.parse(JSON.stringify(coverLamination)) : null;
            }
            return null;
        },
        selectedCorpusPaper() {
            if (this.corpus.papers) {
                const corpusPaper = this.corpus.papers.find(paper => paper.id === this.localSelfPublishedBook.components.corpus.paper.id);
                return corpusPaper ? corpusPaper : null;
            }
            return null;
        },
        bookOrientationIconTooltip() {
            const i18nBookOrientation = this.localSelfPublishedBook.dimensions.orientation === "portrait" ? "portraitOrientation" : "landscapeOrientation";
            return this.$t(`SelfPublishingFlowQuote.${i18nBookOrientation}`);
        },
        totalPages() {
            const { paging } = this.localSelfPublishedBook;
            return paging.black !== undefined && paging.color !== undefined ? parseInt(paging.black) + parseInt(paging.color) : undefined;
        },
        maxBlackPages() {
            const { paging } = this.localSelfPublishedBook;
            return 800 - parseInt(paging.color);
        },
        maxColorPages() {
            const { paging } = this.localSelfPublishedBook;
            return 800 - parseInt(paging.black);
        },
        isLaminationInputDisabled() {
            return this.loading.summary || this.localSelfPublishedBook.components.cover.paper.id === 1;
        },
        isCoverPrintingInputDisabled() {
            const { components } = this.localSelfPublishedBook;
            return this.loading.summary || components.cover.paper.id === 1 || components.cover.paper.id === 15;
        },
        isLoading() {
            return this.loading.downloadQuote || this.loading.summary || this.loading.components;
        },
        physicalPrintingApprovalOptionInSummary() {
            return this.summary.book.options.find(({ name }) => name === "physical-printing-approval");
        },
        assistedProductCreationOptionInSummary() {
            return this.summary.book.options.find(({ name }) => name === "assisted-product-creation");
        },
        productCreationWorkshopOptionInSummary() {
            return this.summary.book.options.find(({ name }) => name === "product-creation-workshop");
        },
        takenOptions() {
            const options = [];
            if (this.options["physical-printing-approval"]) {
                options.push({ name: "physical-printing-approval" });
            }
            if (this.options["product-creation"]) {
                options.push({ name: this.options["product-creation"] });
            }
            return options;
        },
        customDimensionsLimits() {
            if (this.localSelfPublishedBook.dimensions.orientation === "portrait") {
                return {
                    width: { min: 110, max: 210 },
                    height: { min: 180, max: 297 },
                };
            } else {
                return {
                    width: { min: 180, max: 297 },
                    height: { min: 110, max: 210 },
                };
            }
        },
        areOptionsDisabled() {
            return !this.selectedFormat.width || this.loading.summary;
        },
        areCustomDimensionsValid() {
            const { width, height, orientation } = this.localSelfPublishedBook.dimensions;
            return orientation === "portrait" && width <= height || orientation === "landscape" && height <= width;
        },
        customDimensionsNotValidText() {
            const { orientation } = this.localSelfPublishedBook.dimensions;
            if (orientation === "portrait") {
                return this.$t("SelfPublishingFlowQuote.customDimensionsNotValidForPortrait");
            }
            return this.$t("SelfPublishingFlowQuote.customDimensionsNotValidForLandscape");
        },
    },
    async created() {
        try {
            await this.loadBookComponents();
            this.fillOptionsInForm();
            await this.getBookSummary();
        } catch (e) {
            this.$error.displayError(e);
        }
    },
    methods: {
        ...mapActions("sideBarMenu", {
            openAccountSection: "openAccountSection",
        }),
        async loadBookComponents() {
            const availableCorpusPaperIds = [1, 2, 22];
            const promises = await Promise.all([
                this.$soBookQuotationAPI.getBindings(),
                this.$soBookQuotationAPI.getCoverPapers(),
                this.$soBookQuotationAPI.getLaminations(),
                this.$soBookQuotationAPI.getCorpusPapers(),
            ]);
            this.bindings = promises[0].data;
            this.cover.papers = promises[1].data;
            this.cover.laminations = promises[2].data;
            this.corpus.papers = promises[3].data.filter(({ id }) => availableCorpusPaperIds.includes(id));
            this.loading.components = false;
        },
        fillOptionsInForm() {
            if (this.selfPublishedBook.options.find(({ name }) => name === "physical-printing-approval")) {
                this.options["physical-printing-approval"] = true;
            }
            if (this.selfPublishedBook.options.find(({ name }) => name === "assisted-product-creation")) {
                this.options["product-creation"] = "assisted-product-creation";
            } else if (this.selfPublishedBook.options.find(({ name }) => name === "product-creation-workshop")) {
                this.options["product-creation"] = "product-creation-workshop";
            }
            if (this.selfPublishedBook.tax.reduced === undefined) {
                this.localSelfPublishedBook.reducedTax = true;
            } else {
                this.localSelfPublishedBook.reducedTax = this.selfPublishedBook.tax.reduced;
            }
        },
        showLogInSwal() {
            return Swal.fire({
                title: this.$t("SelfPublishingFlowQuote.logInRequired"),
                text: this.$t("SelfPublishingFlowQuote.toProcessPleaseLogIn"),
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: this.shopPrimaryColor,
                confirmButtonText: this.$t("SelfPublishingFlowQuote.logInOrRegister"),
            });
        },
        async fillBookComponents() {
            if (!this.isUserLogged) {
                await this.showLogInSwal();
                return this.openAccountSection();
            }
            this.$emit("fill-book-components", this.localSelfPublishedBook);
        },
        setSelectedBinding(binding) {
            this.localSelfPublishedBook.components.binding = binding ? binding : { id: undefined, name: undefined };
        },
        setSelectedFormat(format) {
            if (format) {
                const selectedWidth = this.localSelfPublishedBook.dimensions.orientation === "portrait" ? format.width : format.height;
                const selectedHeight = this.localSelfPublishedBook.dimensions.orientation === "portrait" ? format.height : format.width;
                this.localSelfPublishedBook.dimensions.width = selectedWidth;
                this.localSelfPublishedBook.dimensions.height = selectedHeight;
                if (!format.height && !format.width) {
                    this.options["product-creation"] = "assisted-product-creation";
                }
            }
        },
        setSelectedCoverPaper(paper) {
            this.localSelfPublishedBook.components.cover.paper = paper ? paper : { id: undefined, name: undefined };
        },
        setSelectedCoverLamination(lamination) {
            this.localSelfPublishedBook.components.cover.lamination = lamination ? JSON.parse(JSON.stringify(lamination)) : { id: undefined, name: undefined };
        },
        setSelectedCorpusPaper(paper) {
            this.localSelfPublishedBook.components.corpus.paper = paper ? paper : { id: undefined, name: undefined };
        },
        async getBookSummary() {
            try {
                if (!this.isFormValid) {
                    return;
                }
                const id = uniqueId();
                this.getQuoteRequestIds.push(id);
                this.loading.summary = true;
                this.localSelfPublishedBook.options = this.takenOptions;
                const bookData = JSON.parse(JSON.stringify(this.localSelfPublishedBook));
                bookData.paging.total = this.totalPages;
                const { data } = await this.$hermesAPI.getQuote(bookData, this.localSelfPublishedBook.quantity);
                if (this.getQuoteRequestIds.findIndex(requestId => requestId === id) === this.getQuoteRequestIds.length - 1) {
                    this.summary.book.price.unit = data.products[0].discountedPrice;
                    this.summary.book.price.total = data.products[0].total;
                    this.summary.book.weight = data.products[0].weight;
                    this.summary.book.thickness = data.products[0].dimensions.thickness;
                    this.summary.book.options = data.products[0].options ? data.products[0].options : [];
                    this.summary.delivery.price = data.delivery.price;
                    this.summary.totalWT = data.totalWT;
                    this.loading.summary = false;
                }
            } catch (e) {
                this.loading.summary = false;
                this.summary.totalWT = 0;
                if (e.response && e.response.data && e.response.data.type === "ORDER_TOO_LARGE") {
                    this.$toasted.error(this.$t("SelfPublishingFlowQuote.orderTooLarge"), { icon: "times" });
                } else {
                    this.$error.displayError(e);
                }
            }
        },
        async focusQuantityInput() {
            await this.$nextTick();
            if (this.$refs.quantityInput) {
                this.$refs.quantityInput.focus();
            }
        },
        async downloadQuote() {
            try {
                this.loading.downloadQuote = true;
                const bookData = JSON.parse(JSON.stringify(this.localSelfPublishedBook));
                bookData.paging.total = this.totalPages;
                const { data } = await this.$hermesAPI.getQuotePDF(bookData, this.localSelfPublishedBook.quantity);
                const blob = new Blob([data], { type: "application/pdf" });
                FileSaver.saveAs(blob, `devis.pdf`);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading.downloadQuote = false;
            }
        },
        displayFormat({ name, width, height }) {
            let str = `${name} `;
            if (width && height) {
                str += this.localSelfPublishedBook.dimensions.orientation === "portrait" ? `(${width} mm x ${height} mm)` : `(${height} mm x ${width} mm)`;
            }
            return str;
        },
        properPrice,
    },
    watch: {
        localSelfPublishedBook: {
            handler(updatedBook) {
                if (updatedBook.components.cover.paper.id === 1) {
                    this.localSelfPublishedBook.components.cover.lamination.id = 3;
                    updatedBook.components.cover.printing.bothSides = false;
                } else if (updatedBook.components.cover.paper.id === 15) {
                    updatedBook.components.cover.printing.bothSides = false;
                }
                if ((updatedBook.components.cover.paper.id === 15 || updatedBook.components.cover.paper.id === 17) &&
                    updatedBook.components.cover.lamination.id === 3) {
                    this.setSelectedCoverLamination({ id: 2 });
                }
                this.getBookSummary();
            },
            deep: true,
        },
        "localSelfPublishedBook.dimensions.orientation"(newOrientation, oldOrientation) {
            if (newOrientation !== oldOrientation) {
                this.localSelfPublishedBook.switchBookDimensions();
            }
        },
        takenOptions: {
            handler(newTakenOptions, oldTakenOptions) {
                this.getBookSummary();
                if (oldTakenOptions.find(({ name }) => name === "assisted-product-creation" || name === "product-creation-workshop") &&
                    !newTakenOptions.find(({ name }) => name === "assisted-product-creation" || name === "product-creation-workshop")) {
                    Swal.fire({
                        title: this.$t("SelfPublishingFlowQuote.youAreAboutToTakeManualImport"),
                        text: this.$t("SelfPublishingFlowQuote.thenYouAreExpert"),
                        icon: "warning",
                        confirmButtonColor: this.shopPrimaryColor,
                        confirmButtonText: this.$t("SelfPublishingFlowQuote.understood"),
                    });
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
    label {
        font-size: 0.85rem;
    }

    span {
        font-size: 0.85rem;
    }

    .card-header {
        padding: 0.30rem 1rem;
        span {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    .card-body {
        padding: 0.50rem 0.75rem 0.75rem;
    }

    .form-control {
        height: 30px;
    }

    .min-height-100 {
        min-height: 100%;
    }

    .summary-label {
        font-size: 0.75rem;
    }

    #book-orientation-icon {
        transition: all 0.5s ease;
    }

    #summary-total-price {
        font-size: 1.6rem;
    }
</style>