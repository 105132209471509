<template>
    <transition name="fade" mode="out-in">
        <APILoading key="loading" v-if="loading" :font-size="24" :text="$t('shared.apiLoading.getWithdrawalPoints')"/>
        <div key="checkout-shipping" v-else id="checkout-shipping">
            <CheckoutStep :step="1" :text="$t('CheckoutShipping.fillRecipient')" icon="user"/>
            <div class="row">
                <div class="form-group col-md-3 col-xs-12">
                    <ValidationProvider ref="recipientInput" v-slot="{ errors, classes }">
                        <label class="label">{{ $t("CheckoutShipping.recipient") }} <span>*</span></label>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons person"/>
                            </span>
                            <input v-model="recipient" class="form-control" required :class="classes"
                                   :placeholder="$t('CheckoutShipping.required')"/>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
            </div>
            <CheckoutStep :step="2" :text="$t('CheckoutShipping.IWantToBeDelivered')"
                          icon="truck-loading" id="checkout-shipping-choose-method-step"/>
            <ul class="nav nav-tabs nav-justified" role="tablist">
                <li v-if="isWithdrawalPointDeliveryEnabled || user.hasExceptionalDeliveryMethod('withdrawalPoint')" class="nav-item"
                    @click="setDeliveryMethod('withdrawalPoint')">
                    <a class="nav-link" href="#checkout-shipping-withdrawal-point"
                       :class="{ active: order.delivery.method === 'withdrawalPoint' }"
                       data-toggle="tab" role="tab">
                        <i class="fa fa-store mr-2"/>
                        <span v-html="atWithdrawalPointText"/>
                    </a>
                </li>
                <li v-if="isHomeDeliveryEnabled || user.hasExceptionalDeliveryMethod('home')" class="nav-item"
                    @click="setDeliveryMethod('home')">
                    <a class="nav-link" href="#checkout-shipping-home" data-toggle="tab"
                       role="tab" :class="{ active: order.delivery.method === 'home' }">
                        <i class="fa fa-home mr-2"/>
                        <span v-html="$t('CheckoutShipping.atHome')"/>
                    </a>
                </li>
            </ul>
            <div class="tab-content pt-2">
                <CheckoutShippingWithdrawalPoints v-if="isWithdrawalPointDeliveryEnabled || user.hasExceptionalDeliveryMethod('withdrawalPoint')"
                                                  :order="order" :address-id="addressId" :withdrawal-points="withdrawalPoints"
                                                  @set-withdrawal-point-address="setWithdrawalPointAddress"/>
                <CheckoutShippingHome v-if="isHomeDeliveryEnabled || user.hasExceptionalDeliveryMethod('home')"
                                      :carriers="carriers" :address-id="addressId"
                                      :carrierId="carrierId" :order="order"
                                      @set-carrier="setCarrier" @reset-carrier-id="resetCarrierId"/>
            </div>
            <transition mode="out-in" name="fade">
                <div class="mt-4" v-if="isStepValid">
                    <hr class="mb-2"/>
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input v-model="saveAsFavoriteDelivery" class="custom-control-input c-pointer" type="checkbox" id="favorite-delivery">
                                <label class="custom-control-label c-pointer" for="favorite-delivery">
                                    {{ $t("CheckoutShipping.favoriteDelivery") }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <RequiredForOneClickOrder/>
                    <div v-if="decodedToken.isAdmin" class="row mt-3 justify-content-center">
                        <div class="custom-control custom-checkbox custom-control-inline">
                            <input v-model="freeDelivery" class="custom-control-input c-pointer" type="checkbox" id="free-delivery">
                            <label class="custom-control-label c-pointer" for="free-delivery">{{ $t("CheckoutShipping.freeDelivery") }}</label>
                            <AdminBadge class="ml-2"/>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="d-flex justify-content-between pt-4 mt-2">
                <a @click="$emit('change-step', 0)" class="btn btn-outline-secondary m-0">{{$t('checkout.back')}}</a>
                <a @click="fillDelivery" :disabled="!isStepValid" class="btn btn-primary m-0">{{$t('checkout.continue')}}</a>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import CheckoutShippingWithdrawalPoints from "./CheckoutShippingWithdrawalPoints";
import WithdrawalPoint from "../../../../classes/WithdrawalPoint";
import APILoading from "../../../shared/Misc/APILoading";
import CheckoutShippingHome from "./CheckoutShippingHome/CheckoutShippingHome";
import RequiredForOneClickOrder from "../../../shared/Order/RequiredForOneClickOrder";
import AdminBadge from "../../../shared/User/AdminBadge";
import CheckoutStep from "../../../shared/Order/CheckoutStep";
import { decodeJWTToken } from "../../../../helpers/API";

export default {
    name: "CheckoutShipping",
    components: {
        CheckoutStep,
        AdminBadge,
        RequiredForOneClickOrder, CheckoutShippingHome, APILoading, CheckoutShippingWithdrawalPoints},
    props: {
        order: {
            type: Object,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            recipient: this.order.delivery.to,
            method: this.order.delivery.method,
            addressId: this.order.delivery.addressId,
            carrierId: this.order.delivery.carrierId,
            address: this.order.delivery.address,
            priceWT: this.order.delivery.priceWT,
            withdrawalPoints: null,
            carriers: null,
            loading: true,
            freeDelivery: false,
            decodedToken: decodeJWTToken(),
            saveAsFavoriteDelivery: !!this.preferences.delivery.method,
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("config", {
            isWithdrawalPointDeliveryEnabled: "isWithdrawalPointDeliveryEnabled",
            isHomeDeliveryEnabled: "isHomeDeliveryEnabled",
            frontConfig: "front",
        }),
        isStepValid() {
            if (!this.recipient || !this.recipient.trim()) {
                return false;
            } else {
                return this.method && (this.method === "withdrawalPoint" && this.addressId !== "0" ||
                        this.method === "home" && this.addressId !== "0" && this.carrierId !== 0);
            }
        },
        atWithdrawalPointText() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("CheckoutShipping.atWithdrawalPoint") : this.$t("CheckoutShipping.atProvisionPlace");
        },
    },
    async mounted() {
        if (this.order.billing.addressId === "0") {
            return this.$emit("change-step", 0);
        }
        await this.getShippingMethods();
        if (this.order.delivery.method === "withdrawalPoint" && this.addressId !== "0") {
            const selectedWithdrawalPoint = this.withdrawalPoints.find(withdrawalPoint => withdrawalPoint._id === this.addressId);
            if (selectedWithdrawalPoint) {
                this.setWithdrawalPointAddress(selectedWithdrawalPoint);
            } else {
                this.addressId = "0";
            }
        }
    },
    methods: {
        async getShippingMethods() {
            try {
                this.loading = true;
                await Promise.all([this.getWithdrawalPoints()]);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
                setTimeout(() => {
                    if (this.$refs.recipientInput) {
                        this.$refs.recipientInput.validate();
                    }
                }, 500);
            }
        },
        async getWithdrawalPoints() {
            const { data } = await this.$hermesAPI.getWithdrawalPoints({ currency: this.chosenCurrency });
            this.withdrawalPoints = data.map(w => new WithdrawalPoint(w));
        },
        setWithdrawalPointAddress(withdrawalPoint) {
            this.method = "withdrawalPoint";
            this.addressId = withdrawalPoint._id;
            this.address = withdrawalPoint.address;
            if (withdrawalPoint.chargedBy === "product") {
                this.priceWT = 0;
                for (let i = 0; i < this.order.products.length; i++) {
                    this.priceWT += withdrawalPoint.priceWT * this.order.products[i].quantity;
                }
            } else {
                this.priceWT = withdrawalPoint.priceWT;
            }
        },
        setDeliveryMethod(method) {
            if (this.method !== method) {
                this.addressId = "0";
                this.carrierId = 0;
            }
        },
        setCarrier(carrier) {
            this.method = "home";
            this.carrierId = carrier.id;
            this.addressId = carrier.addressId;
            this.address = carrier.address;
            this.priceWT = carrier.priceWT;
        },
        fillDelivery() {
            const delivery = {
                to: this.recipient,
                method: this.method,
                addressId: this.addressId,
                address: this.address,
                carrierId: this.carrierId,
                priceWT: this.priceWT,
                free: this.freeDelivery,
                favoriteDelivery: this.saveAsFavoriteDelivery,
            };
            this.$emit("fill-delivery", delivery);
        },
        resetCarrierId() {
            this.carrierId = 0;
        },
    },

    watch: {
        chosenCurrency() {
            this.getShippingMethods();
        },
    },
};
</script>

<style lang="scss" scoped>
    #checkout-shipping-choose-method-step {
        margin-bottom: 0px !important;
    }

    .form-group {
        label {
            span {
                color: red;
            }
        }
    }

    .custom-checkbox {
        .custom-control-label {
            font-size: 17px;
        }
    }
</style>