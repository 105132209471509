<template>
    <div id="checkout-shipping-home" class="tab-pane fade" role="tabpanel"
         :class="{ show: chosenMethod, active: chosenMethod }">
        <CheckoutStep :step="3" :text="$t('CheckoutShippingHome.chooseAddress')" icon="map-pin"/>
        <div id="use-billing-address" class="custom-control custom-checkbox custom-control-inline text-center">
            <input v-model="useBillingAddress" class="custom-control-input"
                   type="checkbox" id="use-billing-address-input">
            <label class="custom-control-label c-pointer ml-2" for="use-billing-address-input">
                {{ $t("CheckoutShippingHome.useBillingAddress") }}
                <div v-html="`(${order.billing.address.name})`"/>
            </label>
        </div>
        <AddressManager :can-edit="false" :display-controls="!useBillingAddress"
                        :default-address-id="addressIdSelected" ref="addressManager"
                        @selected-address="setAddressSelected"/>
        <CheckoutStep :step="4" :text="$t('CheckoutShippingHome.chooseCarrier')" icon="shipping-fast"/>
        <CarrierList @select-carrier="selectCarrier" @reset-carrier-id="$emit('reset-carrier-id')" :order="order"
                     :address-selected="addressSelected" :carrier-id-selected="carrierIdSelected"/>
    </div>
</template>

<script>
import AddressManager from "../../../../shared/Address/AddressManager/AddressManager";
import CarrierList from "./CarrierList";
import CheckoutStep from "../../../../shared/Order/CheckoutStep";

export default {
    name: "CheckoutShippingHome",
    components: {CheckoutStep, CarrierList, AddressManager},
    props: {
        addressId: {
            required: true,
        },
        carrierId: {
            type: Number,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            carrierIdSelected: this.carrierId,
            addressIdSelected: this.addressId,
            addressSelected: null,
            useBillingAddress: false,
            chosenMethod: this.order.delivery.method === "home",
        };
    },
    methods: {
        setAddressSelected(address) {
            if (this.addressSelected !== null && this.addressSelected._id !== 0) {
                this.$emit("reset-carrier-id");
            }
            this.addressSelected = address;
        },
        selectCarrier(carrier) {
            if (!carrier) {
                return;
            }
            this.carrierIdSelected = carrier.id;
            this.addressIdSelected = this.addressSelected._id;
            carrier.addressId = this.addressIdSelected;
            carrier.address = this.addressSelected;
            this.$emit("set-carrier", carrier);
        },
    },
    watch: {
        useBillingAddress(value) {
            if (value) {
                this.$refs.addressManager.setSelectedAddress(this.order.billing.addressId);
            }
        },
        carrierId(value) {
            this.carrierIdSelected = value;
        },
    },
};
</script>

<style scoped>
    #use-billing-address {
        border: 1px solid lightgrey;
        border-radius: 10px;
        padding: 10px;
    }
</style>