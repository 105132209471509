<template>
    <form v-if="!product.alreadyOrdered" id="delete-product-btn" @submit.prevent="deleteProduct">
        <SubmitBtn :loading="loading" :classes="classes" :tooltip-text="$t('DeleteProductBtn.deleteProductFromBooks')"
                   :text="`<i class='fa fa-trash mr-2'></i>${$t('DeleteProductBtn.delete')}`"/>
    </form>
</template>

<script>
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";
import Product from "@/classes/Product";
import Swal from "sweetalert2";

export default {
    name: "DeleteProductBtn",
    components: {SubmitBtn},
    props: {
        product: {
            type: Product,
            required: true,
        },
        classes: {
            type: String,
            default: "btn btn-danger btn-block",
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        confirmDeleteProduct() {
            return Swal.fire({
                title: this.$t("DeleteProductBtn.areYouSure"),
                html: this.$t("DeleteProductBtn.youWillDeleteThisProduct"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                cancelButtonText: this.$t("DeleteProductBtn.cancel"),
            });
        },
        async deleteProduct() {
            try {
                const { value } = await this.confirmDeleteProduct();
                if (value) {
                    this.loading = true;
                    await this.$hermesAPI.deleteProduct(this.product._id);
                    this.$emit("delete-product", this.product);
                    this.$toasted.success(this.$t("DeleteProductBtn.productDeleted"), { icon: "check" });
                }
            } catch (e) {
                this.$error.displayError(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>