<template>
    <div id="administration-users">
        <transition name="fade" mode="out-in">
            <div key="loading" v-if="!users">
                <APILoading :font-size="30" :text="$t('AdministrationUsers.loading')"/>
            </div>
            <div key="users" v-else class="row">
                <div class="col-12">
                    <v-client-table id="users" :data="users" :columns="columns" :options="options">
                        <div slot="fullName" slot-scope="props" v-html="displayedUserFullName(props.row)"/>
                        <div slot="society" slot-scope="props" v-html="displayedUserSociety(props.row)"/>
                        <div slot="isProfessional" slot-scope="props" v-html="displayedUserStatus(props.row)"/>
                        <div slot="active" slot-scope="props" v-html="formatBooleanAsAffirmative(props.row.active)"/>
                        <div slot="isAdmin" slot-scope="props" v-html="formatBooleanAsAffirmative(props.row.isAdmin)"/>
                        <div slot="createdAt" slot-scope="props" v-html="properDate(props.row.createdAt)"/>
                    </v-client-table>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import User from "@/classes/User";
import APILoading from "@/components/shared/Misc/APILoading";
import { formatBooleanAsAffirmative } from "@/helpers/Boolean";
import { properDate } from "@/helpers/date";

export default {
    name: "AdministrationUsers",
    components: { APILoading },
    data() {
        return {
            users: null,
            columns: ["fullName", "society", "email", "accountingNumber", "isProfessional", "active", "isAdmin", "createdAt"],
            options: {
                headings: this.$t("AdministrationUsers.headings"),
                perPage: 10,
                filterable: ["fullName", "society", "email", "accountingNumber", "isProfessional", "active", "isAdmin", "createdAt"],
                sortable: ["fullName", "society", "email", "accountingNumber", "isProfessional", "active", "isAdmin", "createdAt"],
                orderBy: {
                    ascending: false,
                    column: "createdAt",
                },
                filterAlgorithm: {
                    isProfessional(row, query) {
                        query = query.toLowerCase();
                        const status = this.displayedUserStatus(row);
                        return status ? status.toLowerCase().includes(query) : undefined;
                    },
                },
            },
        };
    },
    async created() {
        await this.getUsers();
    },
    methods: {
        async getUsers() {
            try {
                const query = { fields: "email,firstName,lastName,society,isProfessional,createdAt,active,isAdmin,accountingNumber" };
                const { data: users } = await this.$hermesAPI.getUsers(query);
                this.users = users.map(user => new User(user));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        displayedUserFullName(user) {
            return !user.isProfessional ? user.fullName : this.$t("VueClientTable.undefined");
        },
        displayedUserSociety(user) {
            return user.isProfessional ? user.society.name : this.$t("VueClientTable.undefined");
        },
        displayedUserStatus(user) {
            return user.isProfessional ? this.$t("AdministrationUsers.professional") : this.$t("AdministrationUsers.notProfessional");
        },
        formatBooleanAsAffirmative, properDate,
    },
};
</script>

<style scoped>

</style>