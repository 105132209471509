<template>
    <div>
        <span class="d-inline-block bg-success text-white text-xs p-1" v-html="orderProductionLabel"
              :class="orderProductionLabelClass">
        </span>
    </div>
</template>

<script>
export default {
    name: "OrderProductionStatusLabel",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            productionStatuses: {
                pending: {
                    class: "bg-warning",
                    label: this.$t(`OrderProductionStatusLabel.pending`),
                },
                preparing: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.preparing`),
                },
                shipping: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.shipping`),
                },
                shipped: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.shipped`),
                },
                delivered: {
                    class: "bg-success",
                    label: this.$t(`OrderProductionStatusLabel.delivered`),
                },
                canceled: {
                    class: "bg-danger",
                    label: this.$t(`OrderProductionStatusLabel.canceled`),
                },
            },
        };
    },
    computed: {
        orderProductionLabelClass() {
            return this.productionStatuses[this.order.status.production] ? this.productionStatuses[this.order.status.production].class : "";
        },
        orderProductionLabel() {
            return this.productionStatuses[this.order.status.production] ? this.productionStatuses[this.order.status.production].label : "";
        },
    },
};
</script>

<style lang="scss" scoped>

</style>