    <template>
    <transition name="fade" mode="out-in">
        <div id="loading" key="loading" v-if="review === null">
            <APILoading :text="$t('checkout.review.loadingReview')" :font-size="24"/>
        </div>
        <div id="review" key="review" v-else>
            <Alert v-if="isCartFullOfProductsWithAgreement(user)" :text="$t('CheckoutReview.agreementDisclaimer')"
                   type="info" class="mb-3">
                <template v-slot:icon>
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-certificate fa-stack-2x"/>
                        <i class="far fa-handshake fa-stack-1x fa-inverse"/>
                    </span>
                </template>
            </Alert>
            <Alert v-if="review.billing.EUVATReverseCharge" :text="$t('CheckoutReview.EUVATReverseChargeDisclaimer')"
                   type="success" class="mb-3">
                <template v-slot:icon>
                    <i class="fas fa-hand-holding-usd fa-3x"/>
                </template>
            </Alert>
            <Alert v-if="productDifferenceCount"
                   :text="$tc('CheckoutReview.productDifferenceDisclaimerCounter', productDifferenceCount, { counter: productDifferenceCount })"
                   type="warning" class="mb-3">
                <template v-slot:icon>
                    <i class="fa fa-exclamation-triangle fa-3x"/>
                </template>
            </Alert>
            <CheckoutStep :text="$t('checkout.review.reviewYourOrder')" icon="receipt"/>
            <OrderReviewHeader :order="order" :review="review"/>
            <OrderReviewCartDetails ref="cartDetails" :cart="order.products" :review="review" :currency="chosenCurrency"
                                    @edit-order-product="editOrderProduct"/>
            <hr/>
            <div class="row mt-2">
                <div class="col-12">
                    <label for="comment">
                        <i class="fa fa-comment-alt mr-2"/>
                        <span v-html="$t('CheckoutReview.orderComment')"/>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <textarea id="comment" class="form-control" rows="3" v-model="comment" maxlength="300" :placeholder="$t('CheckoutReview.optional')"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <InputAvailableCharacters :input-value="comment" :max-length="300" :trim="true"/>
                </div>
            </div>
            <div class="text-right padding-bottom-1x">
                <span v-html="`${$t('checkout.review.subTotalWT')}: `"/>
                <span class='text-medium text-dark' v-html="getSubTotal"/>
            </div>
            <div class="text-right padding-bottom-1x">
                <span v-html="`${$t('checkout.review.deliveryPriceWT')}: `"/>
                <span class='text-medium text-dark' v-html="getDeliveryTotal"/>
            </div>
            <hr class="padding-bottom-1x">
            <div v-if="isDiscountCodeSystemEnabled">
                <form @submit.prevent="applyDiscountCode">
                    <div class="row align-items-center pb-3">
                        <div class="col-6">
                            <label class="col-12" for="discount-code-input">
                                <i class="fa mr-2" :class="discountCodeIconClasses"/>
                                <span v-html="$t('CheckoutReview.useDiscountCode')"/>
                            </label>
                            <div class="input-group col-12">
                                <input @keydown.space.prevent v-model="capitalizedDiscountCode"
                                       :disabled="loadingDiscountCode || order.discountCode._id"
                                       class="form-control padding-left-1x" id="discount-code-input"/>
                                <span v-if="!order.discountCode._id && !loadingDiscountCode" class="input-group-btn">
                                    <button v-tooltip="$t('CheckoutReview.applyDiscountCode')" type="submit">
                                        <i class="material-icons send mr-2"/>
                                    </button>
                                </span>
                            </div>
                            <div class="col-12 mt-1" v-if="order.discountCode._id">
                                <a @click.prevent="$emit('apply-discount-code', null)" href="#" class="font-italic text-less-bold">
                                    <i class="fa fa-times-circle mr-1"/>
                                    <span v-html="$t('CheckoutReview.dontUseDiscountCodeAnymore')"/>
                                </a>
                            </div>
                        </div>
                        <div class="col-6">
                            <Alert v-if="order.discountCode._id" class="text-center" type="success" :text="discountCodeDisclaimer">
                                <template v-slot:icon>
                                    <i class="fas fa-tag fa-2x"/>
                                </template>
                            </Alert>
                        </div>
                    </div>
                </form>
                <hr class="pb-3"/>
            </div>
            <div id="total" class="text-right padding-bottom-1x">
                <span v-html="`${$t('checkout.review.totalWT')}: `"/>
                <span class='text-medium text-dark' v-html="getTotal"/>
                <span v-if="review.base.currency !== review.request.currency && review.base.total.priceWT !== 0">
                    <span v-html="'=>'"/><span class='text-medium text-dark' v-html="getLocalCurrencyTotal"/>
                </span>
            </div>
            <div class="d-flex justify-content-between">
                <a href="#" @click="$emit('change-step', 1)" class="btn btn-outline-secondary m-0">{{$t('checkout.back')}}</a>
                <a class="btn btn-primary m-0" @click="confirmReview">{{$t('checkout.continue')}}</a>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
import OrderReviewHeader from "../../../shared/Order/OrderReview/OrderReviewHeader";
import OrderReviewCartDetails from "../../../shared/Order/OrderReview/OrderReviewCartDetails/OrderReviewCartDetails";
import APILoading from "../../../shared/Misc/APILoading";
import Order from "../../../../classes/Order";
import Product from "../../../../classes/Product";
import { isCartFullOfProductsWithAgreement } from "../../../../helpers/user";
import { properPrice } from "../../../../helpers/price";
import { arePricesAndDiscountsDifferent } from "../../../../helpers/product";
import { discountCodeDisclaimer } from "../../../../helpers/discount-code";
import Alert from "../../../shared/Misc/Alert";
import CheckoutStep from "../../../shared/Order/CheckoutStep";
import { decodeJWTToken } from "../../../../helpers/API";
import InputAvailableCharacters from "@/components/shared/Forms/InputAvailableCharacters";

export default {
    name: "CheckoutReview",
    components: {InputAvailableCharacters, CheckoutStep, Alert, APILoading, OrderReviewHeader, OrderReviewCartDetails},
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            review: null,
            discountCode: undefined,
            comment: this.order.comment,
            loadingDiscountCode: false,
        };
    },
    created() {
        if (this.order.delivery.addressId === "0") {
            return this.$emit("change-step", 0);
        }
        this.testOrder();
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            userCart: "cart",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
            isDiscountCodeSystemEnabled: "isDiscountCodeSystemEnabled",
        }),
        getSubTotal() {
            const { request } = this.review;
            return this.properPrice(request.total.priceWT - request.delivery.priceWT, this.chosenCurrency, this.currencies);
        },
        getDeliveryTotal() {
            return this.properPrice(this.review.request.delivery.priceWT, this.chosenCurrency, this.currencies);
        },
        getTotal() {
            return this.properPrice(this.review.request.total.priceWT, this.chosenCurrency, this.currencies);
        },
        getLocalCurrencyTotal() {
            return this.properPrice(this.review.base.total.priceWT, this.review.base.currency, this.currencies);
        },
        productDifferenceCount() {
            if (!this.review || this.order.discountCode._id) {
                return undefined;
            }
            return this.userCart.reduce((acc, product) => {
                const otherProduct = this.review.products.find(p => p._id === product._id);
                return arePricesAndDiscountsDifferent(product, otherProduct) ? acc + 1 : acc;
            }, 0);
        },
        capitalizedDiscountCode: {
            set(value) {
                this.discountCode = value.toUpperCase();
            },
            get() {
                return this.order.discountCode.code ? this.order.discountCode.code : this.discountCode;
            },
        },
        discountCodeDisclaimer() {
            return discountCodeDisclaimer(this.order.discountCode, this.currencies);
        },
        discountCodeIconClasses() {
            return {
                "fa-tag": this.loadingDiscountCode === false,
                "fa-spin": this.loadingDiscountCode === true,
                "fa-circle-notch": this.loadingDiscountCode === true,
                "text-success": !!this.order.discountCode._id,
            };
        },
    },
    methods: {
        ...mapActions("user", {
            deleteToUserCart: "deleteToCart",
        }),
        isCartFullOfProductsWithAgreement, properPrice,
        async testOrder() {
            try {
                this.review = null;
                if (this.order.products === null || !this.order.products.length) {
                    return;
                }
                const testOrder = new Order(this.order);
                testOrder.billing.method = this.order.billing.method !== undefined ? this.order.billing.method : "cash";
                const { userId } = decodeJWTToken();
                const response = await this.$hermesAPI.testOrder({ ...testOrder, user: userId }, { currency: this.chosenCurrency });
                this.review = response.data;
                if (this.review.base.delivery.free) {
                    this.review.base.delivery.price = 0;
                    this.review.base.delivery.priceWT = 0;
                    this.review.request.delivery.price = 0;
                    this.review.request.delivery.priceWT = 0;
                }
                this.review.products = this.review.products.map(product => new Product(product));
            } catch (err) {
                if (err.response && err.response.status === 400 && err.response.data.type === "PRODUCT_NOT_AVAILABLE") {
                    await this.unavailableProductWillBeRemovedFromCart(err.response.data.errors[0].productId);
                } else {
                    this.$error.displayError(err);
                }
            }
        },
        async unavailableProductWillBeRemovedFromCart(productId) {
            const product = this.userCart.find(p => p._id === productId);
            await Swal.fire({
                title: this.$t("CheckoutReview.productIsUnavailable", { product: product.title }),
                text: this.$t("CheckoutReview.itWillBeRemovedFromCart"),
                icon: "warning",
                confirmButtonColor: this.shopPrimaryColor,
                confirmButtonText: this.$t("CheckoutReview.understood"),
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: async() => await this.deleteToUserCart(productId),
            });
            this.testOrder();
        },
        manageApplyDiscountCodeErrors(err) {
            this.capitalizedDiscountCode = "";
            if (err.response && err.response.status === 404) {
                this.$toasted.error(this.$t("CheckoutReview.discountCodeNotFound"), { icon: "tag" });
            } else if (err.response && err.response.status === 400) {
                this.$toasted.error(this.$t(`CheckoutReview.discountCodeBadRequestError.${err.response.data.type}`), { icon: "tag" });
            } else {
                this.$error.displayError(err);
            }
        },
        async applyDiscountCode() {
            try {
                if (!this.capitalizedDiscountCode || !this.capitalizedDiscountCode.trim()) {
                    return this.capitalizedDiscountCode = "";
                }
                this.loadingDiscountCode = true;
                const { userId } = decodeJWTToken();
                const testOrder = new Order({ ...this.order, user: userId, discountCode: { code: this.capitalizedDiscountCode } });
                testOrder.billing.method = this.order.billing.method !== undefined ? this.order.billing.method : "cash";
                const response = await this.$hermesAPI.testOrder(testOrder, { currency: this.chosenCurrency });
                this.$emit("apply-discount-code", response.data.discountCode);
                this.$toasted.success(this.$t(`CheckoutReview.discountCodeApplied`), { icon: "tag" });
            } catch (err) {
                this.manageApplyDiscountCodeErrors(err);
            } finally {
                this.loadingDiscountCode = false;
            }
        },
        editOrderProduct(product) {
            this.$emit("edit-order-product", product);
        },
        confirmReview() {
            const data = {
                review: this.review,
                comment: this.comment ? this.comment.trim() : undefined,
            };
            this.$emit("confirm-review", data);
        },
    },
    watch: {
        chosenCurrency() {
            this.testOrder();
        },
        "order.discountCode"() {
            this.testOrder();
        },
        userCart() {
            this.testOrder();
        },
    },
};
</script>

<style lang="scss" scoped>
    #loading {
        height: 50vh;
    }

    #total {
        font-size: 20px;
    }

    #discount-code-input {
        padding-left: 20px !important;
    }

    #discount-code-disclaimer {
        font-size: 1.1rem;
    }
</style>