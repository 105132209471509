import Config from "../../config";
import Address from "./Address";
import Product from "./Product";
import { hasProp } from "../helpers/class";

class User {
    constructor(user = null) {
        this._id = !hasProp(user, "_id") ? 0 : user._id;
        this.firstName = !hasProp(user, "firstName") ? undefined : user.firstName;
        this.lastName = !hasProp(user, "lastName") ? undefined : user.lastName;
        this.email = !hasProp(user, "email") ? undefined : user.email;
        this.mobilePhone = !hasProp(user, "mobilePhone") ? undefined : user.mobilePhone;
        this.accountingNumber = !hasProp(user, "accountingNumber") ? undefined : user.accountingNumber;
        this.society = {
            name: !hasProp(user, "society.name") ? undefined : user.society.name,
            SIRET: !hasProp(user, "society.SIRET") ? undefined : user.society.SIRET,
        };
        this.addresses = !hasProp(user, "addresses") ? [] : user.addresses.map(address => new Address(address));
        this.addresses.reverse();
        this.cart = !hasProp(user, "cart") ? [] : user.cart.map(product => new Product(product));
        this.publishers = {
            agreements: !hasProp(user, "publishers.agreements") ? [] : user.publishers.agreements.map(agreement => agreement),
        };
        this.carriers = {
            agreements: !hasProp(user, "carriers.agreements") ? [] : user.carriers.agreements.map(agreement => agreement),
            privates: !hasProp(user, "carriers.privates") ? [] : user.carriers.privates.map(privateCarrier => privateCarrier),
        };
        this.payments = {
            exceptionalMethods: !hasProp(user, "payments.exceptionalMethods") ? [] : user.payments.exceptionalMethods.map(method => method),
        };
        this.deliveries = {
            exceptionalMethods: !hasProp(user, "deliveries.exceptionalMethods") ? [] : user.deliveries.exceptionalMethods.map(method => method),
        };
        this.createdAt = !hasProp(user, "createdAt") ? 0 : user.createdAt;
        this.updatedAt = !hasProp(user, "updatedAt") ? 0 : user.updatedAt;
        this.connectedAt = !hasProp(user, "connectedAt") ? 0 : user.connectedAt;
        this.preferences = {
            billing: {
                method: !hasProp(user, "preferences.billing.method") ? null : user.preferences.billing.method,
                addressId: !hasProp(user, "preferences.billing.addressId") ? null : user.preferences.billing.addressId,
            },
            delivery: {
                method: !hasProp(user, "preferences.delivery.method") ? null : user.preferences.delivery.method,
                addressId: !hasProp(user, "preferences.delivery.addressId") ? null : user.preferences.delivery.addressId,
                carrierId: !hasProp(user, "preferences.delivery.carrierId") ? null : user.preferences.delivery.carrierId,
            },
        };
        this.active = !hasProp(user, "active") ? true : user.active;
        this.isAdmin = !hasProp(user, "isAdmin") ? false : user.isAdmin;
        this.isProfessional = !hasProp(user, "isProfessional") ? undefined : user.isProfessional;
        this.logged = !hasProp(user, "logged") ? undefined : user.logged;
    }

    get displayedName() {
        let name;
        if (this.isProfessional && this.society.name) {
            name = this.society.name;
        } else if (!this.isProfessional && (this.firstName || this.lastName)) {
            name = `${this.firstName} ${this.lastName}`;
        } else {
            name = this.email;
        }
        return name;
    }

    get canOneClickOrder() {
        const { billing: billingPreferences } = this.preferences;
        const { delivery: deliveryPreferences } = this.preferences;
        return this.logged && billingPreferences.method && billingPreferences.addressId &&
            deliveryPreferences.method && deliveryPreferences.addressId &&
            (deliveryPreferences.method !== "home" || deliveryPreferences.carrierId);
    }

    get isCartEnabled() {
        return Config.products.canAddToCartNotLogged || this.logged;
    }

    get canSeePrices() {
        return Config.products.showPriceNotLogged || this.logged;
    }

    get cartWeight() {
        return this.cart.reduce((acc, product) => product.weight ? acc + product.weight * product.quantity : 0, 0);
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    hasExceptionalPaymentMethod(paymentMethod) {
        return this.payments.exceptionalMethods.includes(paymentMethod);
    }

    hasExceptionalDeliveryMethod(deliveryMethod) {
        return this.deliveries.exceptionalMethods.includes(deliveryMethod);
    }
}

export default User;