<template>
    <div id="administration-orders">
        <transition name="fade" mode="out-in">
            <div key="loading" v-if="!orders">
                <APILoading :font-size="30" :text="$t('AdministrationOrders.loading')"/>
            </div>
            <div key="orders" v-else class="row">
                <div class="col-12">
                    <v-client-table id="orders" :data="orders" :columns="columns" :options="options">
                        <a slot="reference" slot-scope="props" href="#" class="text-medium" v-html="`#${props.row.reference}`"
                           @click.prevent="showProfileOrdersDetailsModal(props.row)"/>
                        <div slot="client" slot-scope="props" v-html="displayedOrderUser(props.row)"/>
                        <div slot="createdAt" slot-scope="props" v-html="properDate(props.row.createdAt)"/>
                        <div slot="sentAt" slot-scope="props" v-html="displayedSendingDate(props.row)"/>
                        <OrderProductionStatusLabel slot="status" slot-scope="props" :order="props.row" class="text-center"/>
                        <OrderPaymentStatusLabel slot="payment" slot-scope="props" :order="props.row" class="text-center"/>
                        <div slot="actions" slot-scope="props">
                            <button class="btn btn-primary btn-xs btn-block" @click.prevent="showProfileOrdersDetailsModal(props.row)">
                                <i class="fa fa-eye mr-2"/>
                                <span v-html="$t('AdministrationOrders.see')"/>
                            </button>
                            <button class="btn btn-warning btn-xs btn-block" @click.prevent="showOrderModal(props.row)">
                                <i class="fa fa-pen mr-2"/>
                                <span v-html="$t('AdministrationOrders.update')"/>
                            </button>
                            <DownloadBilling :order="props.row" classes="btn btn-danger btn-xs btn-block"/>
                        </div>
                    </v-client-table>
                </div>
            </div>
        </transition>
        <ProfileOrdersDetailsModal ref="profileOrdersDetailsModal"/>
        <AdministrationOrderModal ref="administrationOrderModal" @order-updated="updateOrder"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import APILoading from "@/components/shared/Misc/APILoading";
import { properDate } from "@/helpers/date";
import Order from "@/classes/Order";
import OrderPaymentStatusLabel from "@/components/shared/Order/OrderPaymentStatusLabel";
import OrderProductionStatusLabel from "@/components/shared/Order/OrderProductionStatusLabel";
import DownloadBilling from "@/components/shared/Order/DownloadBilling";
import ProfileOrdersDetailsModal from "@/components/Hermes/Profile/ProfileOrders/ProfileOrdersDetailsModal";
import User from "@/classes/User";
import AdministrationOrderModal from "@/components/Administration/AdministrationOrders/AdministrationOrderModal";

export default {
    name: "AdministrationOrders",
    components: {
        AdministrationOrderModal,
        ProfileOrdersDetailsModal,
        DownloadBilling, OrderProductionStatusLabel, OrderPaymentStatusLabel, APILoading,
    },
    data() {
        return {
            orders: null,
            columns: ["reference", "client", "createdAt", "sentAt", "status", "payment", "actions"],
            options: {
                headings: this.$t("AdministrationOrders.headings"),
                perPage: 10,
                sortable: ["reference", "createdAt", "status"],
                filterable: ["reference", "client", "createdAt", "status"],
                orderBy: {
                    ascending: false,
                    column: "createdAt",
                },
                filterAlgorithm: {
                    reference(row, query) {
                        query = query.toLowerCase();
                        const { proForma, bill, credit } = row.billing.reference;
                        return row.reference.toLowerCase().includes(query) ||
                            proForma && `PF${proForma}`.toLowerCase().includes(query) ||
                            bill && `FC${bill}`.toLowerCase().includes(query) ||
                            credit && `AV${credit}`.toLowerCase().includes(query);
                    },
                    client(row, query) {
                        query = query.toLowerCase();
                        const client = this.displayedOrderUser(row);
                        return client ? client.toLowerCase().includes(query) : undefined;
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
    },
    async created() {
        await this.getOrders();
    },
    methods: {
        async getOrders() {
            try {
                const query = { fields: "reference,user,createdAt,status,totalWT,currency,delivery,billing" };
                const { data: orders } = await this.$hermesAPI.getOrders(query);
                this.orders = orders.map(order => new Order(order));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        displayedSendingDate(order) {
            return order.delivery.sendingDate ? this.properDate(order.delivery.sendingDate) : this.$t("AdministrationOrders.sendingDateNotDefined");
        },
        displayedOrderUser(order) {
            if (!order.user) {
                return this.$t("VueClientTable.undefined");
            }
            const client = new User(order.user);
            if (client.isProfessional) {
                return client.society.name ? client.society.name : this.$t("VueClientTable.undefined");
            }
            return client.fullName;
        },
        showProfileOrdersDetailsModal(order) {
            this.$refs.profileOrdersDetailsModal.show(order);
        },
        showOrderModal(order) {
            this.$refs.administrationOrderModal.show(order._id);
        },
        updateOrder(order) {
            const idx = this.orders.findIndex(({ _id }) => _id === order._id);
            if (idx !== -1) {
                this.orders.splice(idx, 1, new Order(order));
            }
        },
        properDate,
    },
};
</script>

<style scoped>

</style>