<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h4>
                    <i class="fas fa-map-pin mr-2 text-primary"/>
                    <span v-html="address.name"/>
                </h4>
                <hr class="mt-1 mb-4"/>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-6">
                <h6>
                    <span class="fas fa-map-marked-alt mr-2 text-primary"/>
                    <span v-html="$t('AddressSection.generalInfo')"/>
                </h6>
                <ul class="list-unstyled text-sm">
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.street')}:`"/>
                        <span v-html="addressStreet"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.zipCode')}:`"/>
                        <span v-html="addressZipCode"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.city')}:`"/>
                        <span v-html="address.city"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.country')}:`"/>
                        <span v-html="address.country.name"/>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <h6>
                    <span class="fas fa-list-ul mr-2 text-primary"/>
                    <span v-html="$t('AddressSection.moreInfo')"/>
                </h6>
                <ul class="list-unstyled text-sm">
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.mobilePhone')}:`"/>
                        <span v-html="addressMobilePhone"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.GLN')}:`"/>
                        <span v-html="addressGLN"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.VATIN')}:`"/>
                        <span v-html="addressVATIN"/>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddressSection",
    props: {
        address: {
            required: true,
            type: Object,
        },
    },
    computed: {
        addressStreet() {
            return this.address.streetBis ? `${this.address.street}, ${this.address.streetBis}` : this.address.street;
        },
        addressZipCode() {
            return this.address.zipCode ? this.address.zipCode : this.$t("AddressSection.none");
        },
        addressMobilePhone() {
            return this.address.mobilePhone ? this.address.mobilePhone : this.$t("AddressSection.none");
        },
        addressGLN() {
            return this.address.gln ? this.address.gln : this.$t("AddressSection.none");
        },
        addressVATIN() {
            return this.address.vatin ? this.address.vatin : this.$t("AddressSection.none");
        },
    },
};
</script>

<style scoped>

</style>