import { hasProp } from "@/helpers/class";

class Address {
    constructor(address = null) {
        this._id = !hasProp(address, "_id") ? 0 : address._id;
        this.name = !hasProp(address, "name") ? undefined : address.name;
        this.street = !hasProp(address, "street") ? undefined : address.street;
        this.streetBis = !hasProp(address, "streetBis") ? undefined : address.streetBis;
        this.zipCode = !hasProp(address, "zipCode") ? undefined : address.zipCode;
        this.city = !hasProp(address, "city") ? undefined : address.city;
        this.country = {
            name: !hasProp(address, "country.name") ? undefined : address.country.name,
            iso: !hasProp(address, "country.iso") ? 0 : address.country.iso,
        };
        this.mobilePhone = !hasProp(address, "mobilePhone") ? undefined : address.mobilePhone;
        this.gln = !hasProp(address, "gln") ? undefined : address.gln;
        this.vatin = !hasProp(address, "vatin") ? undefined : address.vatin;
    }

    sanitize(user) {
        if (!this.streetBis || this.streetBis && !this.streetBis.trim()) {
            this.streetBis = undefined;
        }
        if (!this.zipCode || this.zipCode && !this.zipCode.trim()) {
            this.zipCode = undefined;
        }
        if (!user.isProfessional) {
            this.gln = undefined;
            this.vatin = undefined;
        }
    }

    toString() {
        let str = "";
        if (this.name) {
            str += `${this.name}:`;
        }
        str += ` ${this.street}`;
        if (this.streetBis) {
            str += ` ${this.streetBis}`;
        }
        str += " - ";
        if (this.zipCode) {
            str += ` ${this.zipCode}`;
        }
        str += ` ${this.city} ${this.country.name}`;
        return str;
    }
}

export default Address;