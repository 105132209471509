<template>
    <div>
        <div class="row padding-top-1x mb-4 justify-content-around">
            <div class="col-12 col-md-5">
                <h5>{{ $t('checkout.review.deliveredTo') }}</h5>
                <ul class="list-unstyled text-sm">
                    <li><span class='text-muted'>{{ $t('checkout.review.client') }}:</span> {{ order.delivery.to }}</li>
                    <li><span class='text-muted'>{{ $t('checkout.review.deliveryMethod') }}:</span> {{ deliveryMethod }}</li>
                    <li>
                        <span class='text-muted'>{{ $t('checkout.review.address') }}:</span>
                        {{ order.delivery.address.street }} {{ order.delivery.address.streetBis }},
                        {{ order.delivery.address.zipCode }} {{ order.delivery.address.city }} - {{ order.delivery.address.country.name }}
                    </li>
                    <li v-if="order.delivery.sendingDate">
                        <span class="text-muted">{{ $t('checkout.review.shippedOn') }}:</span>
                        {{ properDate(order.delivery.sendingDate) }}
                    </li>
                    <li>
                        <span class='badge badge-primary'>{{ $t('checkout.review.deliveryPriceWT') }}:</span>
                        {{ deliveryPrice }}
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-5">
                <h5>{{ $t('checkout.review.billedTo') }}</h5>
                <ul class="list-unstyled text-sm">
                    <li>
                        <span class='text-muted'>{{ $t('checkout.review.client') }}:</span> {{ order.billing.to }}
                    </li>
                    <li>
                        <span class='text-muted'>{{ $t('checkout.review.billingMethod') }}:</span>
                        {{ billingMethod }}
                    </li>
                    <li>
                        <span class='text-muted'>{{$t("checkout.review.address")}}:</span>
                        {{ order.billing.address.street }} {{ order.billing.address.streetBis }},
                        {{ order.billing.address.zipCode }} {{ order.billing.address.city }} - {{ order.billing.address.country.name }}
                    </li>
                    <li v-if="order.createdAt">
                        <span class="text-muted">{{$t('checkout.review.billedOn')}}:</span>
                        {{ properDate(order.createdAt) }}
                    </li>
                    <li>
                        <span class='badge badge-success'>{{$t("OrderReviewHeader.totalWT")}}:</span>
                        {{ orderPrice }}
                    </li>
                    <li v-if="doesEUVATChargeReverseApply">
                        <span class='badge badge-success'>
                            <i class="fas fa-check-circle mr-1"></i>
                            {{ $t("OrderReviewHeader.EUVATChargeReverseApplied") }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice } from "../../../../helpers/price";
import { properDate } from "../../../../helpers/date";

export default {
    name: "OrderReviewHeader",
    props: {
        order: {
            type: Object,
            required: true,
        },
        review: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        deliveryMethod() {
            if (this.order.delivery.method === "withdrawalPoint") {
                return this.$t("checkout.review.withdrawalPoint");
            } else if (this.order.delivery.method === "home") {
                return this.$t("checkout.review.home");
            }
            return "Unknown";
        },
        deliveryPrice() {
            if (this.review) {
                return this.properPrice(this.review.request.delivery.priceWT, this.chosenCurrency, this.currencies);
            } else {
                let price = this.order.delivery.priceWT;
                if (this.order.delivery.free) {
                    price = 0;
                }
                return this.properPrice(price, this.order.currency, this.currencies);
            }
        },
        orderPrice() {
            return this.review ? properPrice(this.review.request.total.priceWT, this.chosenCurrency, this.currencies) : properPrice(this.order.totalWT, this.order.currency, this.currencies);
        },
        billingMethod() {
            return this.order.billing.method === undefined ? this.$t("checkout.review.toDefine") : this.$t(`OrderReviewHeader.${this.order.billing.method}`);
        },
        doesEUVATChargeReverseApply() {
            return this.review && this.review.billing.EUVATReverseCharge || !this.review && this.order && this.order.billing.EUVATReverseCharge;
        },
    },
    methods: {
        properDate, properPrice,
    },
};
</script>

<style scoped>

</style>