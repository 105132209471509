<template>
    <div id="card-border">
        <div id="card-disclaimer">
            <i class="fa fa-lock mr-1 text-primary"/>
            <span v-html="$t('StripePayment.securedPayment')"/>
        </div>
        <hr class="mt-2 mb-2"/>
        <div id="card-element" class="shadow-lg"/>
        <div id="card-errors" class="col-12 text-center mt-2" role="alert" v-if="stripe.error">
            <i class="fa fa-times text-danger mr-1"/>
            <span v-html="stripe.error"/>
        </div>
        <SubmitBtn classes="btn btn-info btn-block pb-0 mb-0" :disabled="!stripe.card"
                   :text="$t('StripePayment.payWithCreditCard')" @click.prevent="payWithCreditCard"
                   :loading="loading"/>
    </div>
</template>

<script>
import SubmitBtn from "../Misc/SubmitBtn";
import Config from "../../../../config";
import Order from "../../../classes/Order";

export default {
    name: "StripePayment",
    components: {SubmitBtn},
    props: {
        order: {
            type: Object,
            required: true,
        },
        forcedLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localOrder: new Order(this.order),
            stripe: {
                instance: undefined,
                card: undefined,
                error: "",
                style: {
                    base: {
                        color: "#32325d",
                        fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
                        fontSmoothing: "antialiased",
                        fontSize: "20px",
                        "::placeholder": {
                            color: "#aab7c4",
                        },
                    },
                },
            },
            loading: false,
        };
    },
    mounted() {
        this.localOrder.billing.method = "creditCard";
        // eslint-disable-next-line no-undef
        this.stripe.instance = Stripe(Config.stripe.keys.public);
        this.stripe.card = this.stripe.instance.elements().create("card", { style: this.stripe.style });
        this.stripe.card.mount("#card-element");
        this.stripe.card.addEventListener("change", ({ error }) => {
            this.stripe.error = error ? error.message : "";
        });
    },
    methods: {
        async payWithCreditCard() {
            try {
                this.loading = true;
                this.stripe.error = "";
                const response = await this.stripe.instance.createPaymentMethod({ type: "card", card: this.stripe.card });
                if (!response.error) {
                    let { data: paymentIntent } = await this.$hermesAPI.createStripePayment({ ...this.localOrder, paymentMethodId: response.paymentMethod.id });
                    if (paymentIntent.status === "requires_action") {
                        const stripeResponse = await this.stripe.instance.handleCardAction(paymentIntent.client_secret);
                        if (stripeResponse.error) {
                            return this.stripe.error = stripeResponse.error.message;
                        }
                        const { data } = await this.$hermesAPI.confirmStripePayment({ paymentIntentId: paymentIntent.id });
                        paymentIntent = data;
                    }
                    this.$emit("payment-intent", paymentIntent);
                }
            } catch (err) {
                this.handleStripePaymentError(err);
            } finally {
                this.loading = false;
            }
        },
        handleStripePaymentError(error) {
            if (error && error.response && error.response.data && error.response.data.code === 24) {
                const stripeCode = error.response.data.errors[0].decline_code ? error.response.data.errors[0].decline_code : error.response.data.errors[0].code;
                const stripeCodeLabel = this.$t(`StripePayment.stripeErrorCode.${stripeCode}`);
                this.stripe.error = stripeCodeLabel;
                this.$toasted.error(stripeCodeLabel, { icon: "times" });
            } else {
                this.$error.displayError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #card-border {
        border: 1px solid lightgrey;
        padding: 20px;
        border-radius: 10px;
    }

    #card-disclaimer {
        font-size: 0.7rem;
        font-style: italic;
    }
</style>