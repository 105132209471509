<template>
    <div id="print-corp-home">
        <section class="hero-slider">
            <div class="owl-carousel large-controls dots-inside">
                <router-link to="/self-publishing/create-book">
                    <img :src="IMGs.slide1" alt="Slide 1"/>
                </router-link>
                <router-link to="/self-publishing/create-book">
                    <img :src="IMGs.slide2" alt="Slide 2"/>
                </router-link>
                <router-link to="/self-publishing/create-book">
                    <img :src="IMGs.slide3" alt="Slide 3"/>
                </router-link>
                <router-link to="/self-publishing/create-book">
                    <img :src="IMGs.slide4" alt="Slide 4"/>
                </router-link>
                <a href="https://www.typolibris.fr/la-clairiere-des-livres/" target="_blank">
                    <img :src="IMGs.slide5" alt="Slide 5"/>
                </a>
            </div>
        </section>
        <div id="self-publish-disclaimer" class="d-flex justify-content-center align-items-center p-4 my-5">
            <img :src="IMGs.logo" width="250" alt="imprimervoslivres.com"/>
            <div class="ml-5">
                <h4 class="mb-1" v-html="'vous aide grâce à son calculateur simple,'"/>
                <h4 v-html="'à réaliser votre projet d’impression'"/>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/img/imprimer-vos-livres/custom-home/imprimer-vos-livres-logo.png";
import slide1 from "@/assets/img/imprimer-vos-livres/custom-home/slider/slide-1.jpg";
import slide2 from "@/assets/img/imprimer-vos-livres/custom-home/slider/slide-2.jpg";
import slide3 from "@/assets/img/imprimer-vos-livres/custom-home/slider/slide-3.jpg";
import slide4 from "@/assets/img/imprimer-vos-livres/custom-home/slider/slide-4.jpg";
import slide5 from "@/assets/img/imprimer-vos-livres/custom-home/slider/slide-5.png";

export default {
    name: "PrintCorpHome",
    data() {
        return {
            IMGs: { logo, slide1, slide2, slide3, slide4, slide5 },
        };
    },
    mounted() {
        // eslint-disable-next-line no-undef
        $(".owl-carousel").owlCarousel({
            items: 1,
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            dots: true,
            nav: true,
            navText: false,
            autoplay: true,
            autoplayTimeout: 8000,
        });
    },
};
</script>

<style lang="scss" scoped>
    #print-text {
        color: #ED2F22;
        font-size: 3.5rem;
    }

    #my-book-text {
        color: #079444;
        font-size: 3rem;
    }

    #start-to-self-publish {
        background-color: #D6DB30;
    }

    #start-to-self-publish-btn {
        font-size: 1.5rem;
    }
</style>