<template>
    <div class="container mt-5">
        <section class="row align-items-center my-2 text-justify">
            <div class="col-2">
                <img :src="IMGs.typolibris" class="img-fluid" alt="Typolibris"/>
            </div>
            <div class="col-10">
                <p>
                    <span class="text-less-bold">Imprimervoslivres.com</span> est un service de
                    <span class="text-less-bold">Typo’Libris</span>, l’imprimeur des éditeurs, qui consiste pour
                    vous et depuis plus de 25 ans, à gérer la fabrication de vos livres dans l’objectif permanent de vous
                    garantir la qualité et le délai au meilleur prix.
                </p>
            </div>
        </section>
        <section class="row align-items-center my-2 justify-content-end text-justify">
            <div class="col-9">
                <p>
                    Avec <span class="text-less-bold">Imprimervoslivres.com</span>,
                    vous pouvez commander directement en ligne
                    l’impression de vos courts tirages en numérique, dès 1 exemplaire.
                    Déterminez rapidement et simplement, le meilleur tarif pour vos projets
                    d’impression grâce au calculateur de prix.
                </p>
            </div>
            <div class="col-2 text-center">
                <img :src="IMGs.calculator" width="75" class="img-fluid" alt="Calculator"/>
            </div>
        </section>
        <section class="row align-items-center my-2 text-justify">
            <div class="col-2">
                <img :src="IMGs.lockedFolder" class="img-fluid" alt="Locked folder"/>
            </div>
            <div class="col-10">
                <p>
                    Pour une gestion optimisée de vos nouvelles impressions et réimpressions, vous
                    disposez d’un espace de stockage de vos fichiers sur votre compte personnel sécurisé.
                </p>
            </div>
        </section>
        <section class="row align-items-center my-2 justify-content-end text-justify">
            <div class="col-9">
                <p>
                    Notre équipe de professionnels des arts graphiques est à votre disposition
                    afin que vous bénéficiez de leurs expertises et conseils.
                </p>
            </div>
            <div class="col-2 text-center">
                <img :src="IMGs.businessMen" width="125" class="img-fluid" alt="Business men"/>
            </div>
        </section>
        <section class="row align-items-center my-2 text-justify">
            <div class="col-2">
                <img :src="IMGs.madeInFrance" class="img-fluid" alt="Made in France "/>
            </div>
            <div class="col-10">
                <p>
                    Notre partenaire français dispose d’un atelier de fabrication garantissant une
                    conduite éco-responsable de l’impression de vos livres.
                </p>
                <p>
                    Imprimez en France, et recevez vos livres en 3 jours ouvrés !
                </p>
            </div>
        </section>
        <hr/>
    </div>
</template>

<script>
import typolibris from "@/assets/img/imprimer-vos-livres/about/typolibris.jpg";
import businessMen from "@/assets/img/imprimer-vos-livres/about/business-men.jpg";
import calculator from "@/assets/img/imprimer-vos-livres/about/calculator.jpg";
import lockedFolder from "@/assets/img/imprimer-vos-livres/about/locked-folder.jpg";
import madeInFrance from "@/assets/img/imprimer-vos-livres/about/made-in-france.png";

export default {
    name: "AboutImprimerVosLivres",
    data() {
        return {
            IMGs: { typolibris, businessMen, calculator, lockedFolder, madeInFrance },
        };
    },
};
</script>

<style scoped>

</style>